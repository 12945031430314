/* eslint-disable import/no-import-module-exports */

/** @jsxRuntime classic */ // needed on the very top to work properly with IE11

import './polyfills.js';
import 'inobounce';

import React from 'react';
import { createRoot } from 'react-dom/client';

import { getLocale, LOCALE_TYPE, getTranslations } from '@sam/i18n';
import { initDatadogRum } from '@sam/analytics';

const locale: LOCALE_TYPE = getLocale();

// used due to the following bug: https://bugs.webkit.org/show_bug.cgi?id=141832
const setVhCssCustomProperty = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};
window.addEventListener('resize', setVhCssCustomProperty);
setVhCssCustomProperty();

// init datadog RUM
initDatadogRum();

Promise.all([getTranslations(locale), import(/* webpackChunkName: "app" */ './components/app')]).then(
  ([translations, { App }]: [{ [key: string]: string }, { App: React.FC<any> }]) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    clearTimeout(window.timerForShowingGlobalGeneralLoader);

    const container = document.getElementById('root');
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const root = createRoot(container!);
    root.render(<App locale={locale} translations={translations} />);
  },
);

import { getCountryCode } from '@sam/auth';

export type LOCALE_TYPE =
  | 'bg-BG'
  | 'de-DE'
  | 'en-IN'
  | 'en-PK'
  | 'en-US'
  | 'en-ES'
  | 'es-ES'
  | 'fr-BE'
  | 'fr-FR'
  | 'hr-HR'
  | 'hu-HU'
  | 'it-IT'
  | 'ja-JP'
  | 'nl-BE'
  | 'nl-NL'
  | 'pt-PT'
  | 'ro-MD'
  | 'ro-RO'
  | 'rs-RS'
  | 'ru-KZ'
  | 'ru-RU'
  | 'sr-RS'
  | 'tr-TR'
  | 'uk-UA'
  | 'zh-CN';

export const FALLBACK_LOCALE: LOCALE_TYPE = 'en-US';

export const LANGUAGE_TO_LOCALE_MAP: { [key: string]: LOCALE_TYPE } = {
  bg: 'bg-BG',
  'bg-bg': 'bg-BG',
  de: 'de-DE',
  'de-de': 'de-DE',
  en: 'en-US',
  'en-in': 'en-IN',
  'en-pk': 'en-PK',
  'en-us': 'en-US',
  'en-es': 'en-ES',
  es: 'es-ES',
  'es-es': 'es-ES',
  fr: 'fr-FR',
  'fr-be': 'fr-BE',
  'fr-fr': 'fr-FR',
  hr: 'hr-HR',
  'hr-hr': 'hr-HR',
  hu: 'hu-HU',
  'hu-hu': 'hu-HU',
  it: 'it-IT',
  'it-it': 'it-IT',
  ja: 'ja-JP',
  'ja-jp': 'ja-JP',
  nl: 'nl-NL',
  'nl-be': 'nl-BE',
  'nl-nl': 'nl-NL',
  pt: 'pt-PT',
  'pt-pt': 'pt-PT',
  ro: 'ro-RO',
  'ro-ro': 'ro-RO',
  mo: 'ro-MD',
  'ro-md': 'ro-MD',
  rs: 'rs-RS',
  'rs-rs': 'rs-RS',
  ru: 'ru-RU',
  'ru-ru': 'ru-RU',
  kk: 'ru-KZ',
  'ru-kz': 'ru-KZ',
  sr: 'sr-RS',
  'sr-rs': 'sr-RS',
  tr: 'tr-TR',
  'tr-tr': 'tr-TR',
  uk: 'uk-UA',
  'uk-ua': 'uk-UA',
  zh: 'zh-CN',
  'zh-cn': 'zh-CN',
};

const getBrowserLanguage = (): string | void => {
  const knownLanguageList = navigator.languages
    ?.filter((lang) => !!LANGUAGE_TO_LOCALE_MAP[lang.toLowerCase()])
    .map((lang) => lang.toLocaleLowerCase());

  return (knownLanguageList && knownLanguageList[0]) || navigator.language;
};

const handleLocaleForBelgium = (language: string | void): LOCALE_TYPE | void => {
  const countryCode = getCountryCode();

  if (countryCode === 'BE' && language) {
    if (['fr', 'fr-fr'].includes(language.toLowerCase())) {
      return 'fr-BE';
    }
    if (['nl', 'nl-nl'].includes(language.toLowerCase())) {
      return 'nl-BE';
    }
  }

  return undefined;
};

export const getLocale = (): LOCALE_TYPE => {
  const language = getBrowserLanguage();
  return handleLocaleForBelgium(language) || LANGUAGE_TO_LOCALE_MAP[language || FALLBACK_LOCALE.toLowerCase()];
};

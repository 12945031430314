import { LOCALE_TYPE, FALLBACK_LOCALE } from '../get-locale/get-locale';

export const PATH_TO_TRANSLATIONS_CONFIG = '/translations/config/translations-config.json';

const getContentHashForTranslations = async (locale: LOCALE_TYPE): Promise<string> => {
  const translationsConfigFile = await fetch(PATH_TO_TRANSLATIONS_CONFIG);
  const translationsConfig = await translationsConfigFile.json();

  return translationsConfig.LANGUAGE_CONTENT_HASH_MAP[locale];
};

type TranslationsMap = { [key: string]: string };

export const getTranslations = async (locale: LOCALE_TYPE = FALLBACK_LOCALE): Promise<TranslationsMap> => {
  const contentHash = await getContentHashForTranslations(locale);
  const fallbackContentHash = await getContentHashForTranslations(FALLBACK_LOCALE);

  const fileName = contentHash ? `${locale}.${contentHash}` : `${FALLBACK_LOCALE}.${fallbackContentHash}`;

  const res = await fetch(`/translations/${fileName}.json`);
  const translations = await res.json();

  return translations;
};

import Cookies from 'js-cookie';

import { COUNTRY_CODE } from '@sam/types';

export enum COOKIE {
  COUNTRY = 'sf-sm-country', // TODO please check if we need this cookie anymore in the whole project (it may be a redundant relict from SAM 1.0)
  USER = 'sf-user',
  TOKEN = 'sf-auth-token',
  ORG = 'sf-sm-org',
  ENV = 'sf-env',
  CSRF = 'sf-csrf',
  USERID = 'sf-user-id',
  ROLES = 'sf-roles',
}

export const getUserIdCookie = () => {
  return Cookies.get(COOKIE.USERID);
};

export const getCountryCode = () => {
  return (Cookies.getJSON(COOKIE.ORG) ?? 'n/a') as COUNTRY_CODE | 'n/a';
};

export const isCurrentUser = (email: string) => {
  const userEmail = Cookies.getJSON(COOKIE.USER);
  return userEmail?.toLowerCase() === email.toLowerCase();
};

export const isCountryAdmin = () => {
  const roles = Cookies.get(COOKIE.ROLES);
  return roles ? roles.split(':').includes('SF_COUNTRY_ADMIN') : false;
};

export const isSamHierarchyPositionManager = () => {
  const roles = Cookies.get(COOKIE.ROLES);
  return roles ? roles.split(':').includes('SAM_HIERARCHY_POSITION_MANAGER') : false;
};

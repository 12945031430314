import React from 'react';

import { LoggerContext } from './logger-context';
import { useBrowserLogger } from './use-browser-logger';

type Props = {
  children?: React.ReactNode;
};

export const LoggerProvider: React.FC<Props> = ({ children }) => {
  const contextValue = useBrowserLogger();

  return <LoggerContext.Provider value={contextValue}>{children}</LoggerContext.Provider>;
};
LoggerProvider.displayName = 'LoggerProvider';

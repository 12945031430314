import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

// needed for IE11
(function CustomEventPolyfill() {
  if (typeof window.Event === 'function') return false; // If not IE

  function CustomEvent(event, params) {
    // eslint-disable-next-line
    params = params || {
      bubbles: false,
      cancelable: false,
      detail: undefined,
    };
    const evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
    return evt;
  }

  CustomEvent.prototype = window.Event.prototype;

  window.Event = CustomEvent;
})();

// needed for these browsers: https://caniuse.com/?search=randomUUID - e.g. MSAL package is using crypto.randomUUID under the hood
// also needed for all pages, which don't have https configured - for instance the Fake App
if (!window.crypto.randomUUID) {
  window.crypto.randomUUID = function polyfillCryptoRandomUUID() {
    let d = new Date().getTime(); // Timestamp
    let d2 = (performance && performance.now && performance.now() * 1000) || 0; // Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function replacer(c) {
      let r = Math.random() * 16; // random number between 0 and 16
      if (d > 0) {
        // Use timestamp until depleted
        // eslint-disable-next-line no-bitwise
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {
        // Use microseconds since page-load if supported
        // eslint-disable-next-line no-bitwise
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      // eslint-disable-next-line no-bitwise
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
  };
}

import React from 'react';
import { datadogLogs, Logger } from '@datadog/browser-logs';

import { LoggerContextType } from './logger-context';

const DD_SITE = 'datadoghq.eu';
const SAM_SERVICE_NAME = 'msam.planning.sam-web-2.0';
const NOT_AVAILABLE = 'N/A';

const isProd = () => window.samEnv?.ENVIRONMENT === 'MCC2-PROD';
const isPp = () => window.samEnv?.ENVIRONMENT === 'PP';

const isBrowserLoggerEnabled = () => {
  const isEnabledForEnvironment = isPp() || isProd();
  const isTokenSet = Boolean(window.samEnv?.DD_CLIENT_TOKEN);
  return isEnabledForEnvironment && isTokenSet;
};

export const useBrowserLogger = (): LoggerContextType => {
  const [logger, setLogger] = React.useState<Logger | null>(null);

  React.useEffect(() => {
    if (!isBrowserLoggerEnabled()) {
      return;
    }

    // @ts-ignore
    const { DD_CLIENT_TOKEN, BUILD_VERSION, ENVIRONMENT } = window.samEnv;

    datadogLogs.init({
      clientToken: DD_CLIENT_TOKEN,
      service: SAM_SERVICE_NAME,
      version: BUILD_VERSION ?? NOT_AVAILABLE,
      env: ENVIRONMENT?.toLowerCase(),
      site: DD_SITE,
    });

    setLogger(
      datadogLogs.createLogger('planning', {
        handler: 'http',
        level: isProd() ? 'info' : 'debug',
      }),
    );
  }, []);

  return {
    planningLogger: logger as Logger,
  };
};
